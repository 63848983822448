html, body, #root {
  height: 100%;
}

.app {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.btn {
  border: none !important;
  margin: 0px 25px !important;
  padding: 0 !important;
  text-transform: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0) !important;
  transition: border-color 350ms;
}

.btn:hover {
  background-color: rgba(0, 0, 0, 0) !important;
  border-bottom: 1px solid !important;
  border-radius: 0 !important;
  transition: border-color 350ms;
}

.section {
  padding-left: 20px;
  padding-right: 20px;
  flex-grow: 1;
  min-height: 0;
  overflow: auto;
}
.btn-group {
  width: 100% !important;
  padding: 20px 0px;
  color: black !important;
}

.space {
  min-width: 0;
  flex-grow: 1;
}

.menu-active {
  border-bottom: 1px solid !important;
  border-radius: 0 !important;
}

.box {
  box-sizing: border-box;
}
.box:not(:first-child) {
  border-left: 1px solid #ddd;
}
.box:not(:last-child) {
  border-right: 1px solid #ddd;
}

.grid {
  margin: 0 !important;
  width: 100% !important;
  height: calc(100vh - 66px) !important;
}

.resources {
  margin: 0px 20px 0 20px;
}

h4 {
  text-align: center;
  color: #808080 !important;
  margin-bottom: 50px;
}

.zbytek {
  margin-top: 15px;
  width: 100%;
  height: 45vh;
  flex-grow: 1;
}


.space-20 {
  height: 20px;
}

.parking-interval {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.item-space {
  margin-top: 8px!important;
  margin-bottom:  8px!important;
}

.map-container {
  position: relative;
  height: 100%;
  width: 100%;
  min-height: 50vh;
}
.map-container__map {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  
}
.list-customer:hover {
  cursor: pointer;
}

